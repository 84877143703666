interface ICustomWindow extends Window {
    dataLayer: Array<unknown>;
}
  
declare let window: ICustomWindow;

export namespace GoogleTagManager {
    export const init = (): void => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
        });
    };
}