import Layout from '@/pages/RootLayout';
import Script from 'next/script';
import { AppProps } from 'next/app';
import { GoogleTagManager } from '@/utils/googleTagManager';
import '@/pages/globals.css'
import Head from 'next/head';
import { ReactElement, ReactNode } from 'react';
import { NextPage } from 'next';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)

  return getLayout(
    <>
      <Script
        src={`https://www.googletagmanager.com/gtm.js?id=${process.env['NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID']}`}
        onLoad={() => {
          GoogleTagManager.init();
        }}
      />
      {/* <Layout> */}
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        </Head>
        <Component {...pageProps} />
      {/* </Layout> */}
    </>
  )
}